import React, { useState, useMemo, useCallback } from "react";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";


const NestedTable = ({ nestedData, depth, isOpen, path }) => {
  const tableName = Object.keys(nestedData)[0];
  const records = nestedData[tableName]["records"];
  const currentPath = path + "." + tableName + ".records";
  const [isShowKids, setShowKids] = useState(false);
  const setOpenCallback = useCallback(() => {
    setShowKids(!isShowKids);
  }, [isShowKids]);
  const columns = useMemo(
    () =>
      records[0].hasOwnProperty("data")
        ? Object.keys(records[0]["data"])
        : undefined,
    [records]
  );

  return (
    <React.Fragment>
      {records.length !== 0 && columns !== undefined && (
        <tr
          className="persons-table--nested"
          style={{ marginLeft: depth * 30, display: isOpen ? "block" : "none" }}
        >
          {records.map(
            (record, index) =>
              record.hasOwnProperty("data") && (
                <React.Fragment key={`${index}-${tableName}`}>
                  {index === 0 && <h3>{tableName}</h3>}
                  <TableHeader columns={[...columns]} />
                  <TableRow
                    records={record.data}
                    columns={columns}
                    clickCallback={() => setOpenCallback()}
                    hasKids={Object.keys(record.kids).length}
                    toggleStatus={isShowKids}
                    path={`${currentPath}.${index}.data`}
                  />
                  {Object.keys(record.kids).length !== 0 && (
                    <NestedTable
                      nestedData={record.kids}
                      depth={depth++}
                      isOpen={isShowKids}
                      hasKids={true}
                      path={`${currentPath}.${index}.kids`}
                    />
                  )}
                </React.Fragment>
              )
          )}
        </tr>
      )}
    </React.Fragment>
  );
};
export default NestedTable;
