import React, { useState, useCallback } from "react";
import TableRow from "./TableRow";
import NestedTable from "./NestedTable";
import TablesContext from "../TablesContext";

const TableBody = ({tablesData, index}) => {
  const { headerColumnCount, headers } = React.useContext(TablesContext);
  const [isOpen, setOpen] = useState(false);
  let hasKids = false;
  const kidKey = Object.keys(tablesData.kids)[0];
  const kidData = 'kids' in tablesData && tablesData.kids[kidKey];
  if ( kidData !== undefined && kidData.records[0].hasOwnProperty("data")){
    hasKids = true
  }

  const toggleOpen = useCallback( () => {
    setOpen(!isOpen)
  },[isOpen])

      return (
        <React.Fragment>
          <TableRow
            records={tablesData.data}
            columns={headers}
            hasKids={hasKids}
            isOpen={isOpen}
            clickCallback={() => toggleOpen()}
            path={index}
          />
          {isOpen && hasKids && (
            <tr>
              <td colSpan={headerColumnCount}>
                <table>
                  <tbody>
                    <NestedTable
                      nestedData={tablesData.kids}
                      depth={1}
                      isOpen={true}
                      path={`${index}.kids`}
                    />
                  </tbody>
                </table>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
};

export default TableBody
